<template>
<div>
  <div class="card-box-full" style="overflow: visible">
    <div class="card-box-header">
      Ping
    </div>

    <div class="form-group row" style="margin-top: 20px">
      <div class="col-md-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <CDropdown color="secondary" :toggler-text="method" :disabled="isExecuting">
              <CDropdownItem @click="method = 'GET'">GET</CDropdownItem>
              <CDropdownItem @click="method = 'POST'">POST</CDropdownItem>
              <CDropdownItem @click="method = 'PUT'">PUT</CDropdownItem>
              <CDropdownItem @click="method = 'DELETE'">DELETE</CDropdownItem>
              <CDropdownItem @click="method = 'HEAD'">HEAD</CDropdownItem>
            </CDropdown>
          </div>
          <input class="form-control" v-model="url" @focus="focusedURL" type="url" autocomplete="on" placeholder="Enter a URL to ping" :disabled="isExecuting" @keyup.enter="execute()">

        </div>
        <div style="height: 20px"></div>
        <textarea v-if="method == 'POST' || method == 'PUT'" v-bind:value="body" @input="updateBody" placeholder="Request body goes here" style="width: 100%; height: 150px; margin-bottom: 10px" :disabled="isExecuting"></textarea>

        <div style="margin-bottom: -10px">
          <table width="100%">
            <tr>
              <td valign="middle" align="left" width="50%">
                <table>
                  <tr>
                    <td valign="middle" align="left">
                      Send your session token with the request?
                    </td>
                    <td width="10">
                    </td>
                    <td valign="middle" align="left">
                      <CSwitch color="primary" :checked.sync="includeSessionToken" value="color" labelOn="YES" labelOff="NO" :disabled="isExecuting" />
                    </td>
                  </tr>
                </table>

              </td>
              <td valign="middle" align="right" width="50%">
                <CButton v-if="isExecuting" @click="stopExecuting()" color="danger">
                  <div>STOP</div>
                </CButton>
                <CButton v-else @click="execute()" color="success" :disabled="isExecuting || url.length == 0">
                  <div>Start Pinging</div>
                </CButton>

              </td>

            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>

  <div v-if="isExecuting || results.length > 0" class="card-box-full" style="overflow: visible">
    <div class="card-box-header">
      <table width="100%">
        <tr>
          <td align="left" valign="middle">
            Results
          </td>
          <td align="right" valign="middle">
            <div v-if="isPinging" class="spinner-border spinner-border-sm text-primary" role="status">
              <span class="sr-only"></span>
            </div>
            <span style="margin-left: 10px; font-size: 12px">{{ maxPings - results.length }} pings remaining</span>
          </td>
        </tr>
      </table>
    </div>

    <div style="max-height: 250px; overflow: auto; display: flex; flex-direction: column-reverse;">
      <table style="margin-top: 10px">

        <transition-group tag="tbody" name="fade">

          <tr v-for="result in results" :key="result.id">
            <td valign="middle">
              <span style="font-size: 12px">{{ result.time.toLocaleTimeString('en-US') }}</span>
            </td>
            <td align="center" valign="middle" style="padding-left: 10px">
              <span v-if="result.code >= 200 && result.code < 300" class="badge badge-pill badge-success">{{ result.code }}</span>
              <span v-else-if="result.code == 0" class="badge badge-pill badge-danger">ERROR</span>
              <span v-else class="badge badge-pill badge-warning">{{result.code }}</span>
            </td>
            <td valign="middle" style="padding-left: 10px;">
              <span class="badge badge-pill badge-light" style="margin-top: 7px; white-space: nowrap; max-width: 550px; text-overflow: ellipsis; overflow: hidden; font-family: monospace;">{{ result.body }}</span>
            </td>
          </tr>

        </transition-group>

      </table>
    </div>

  </div>
</div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}
</style>
<script>
export default {
  name: 'Ping',
  data() {
    return {
      body: "",
      includeSessionToken: false,
      isExecuting: false,
      isPinging: false,
      maxPings: 500,
      method: "GET",
      pingTimer: null,
      results: [],
      url: ""
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    execute() {
      if (this.isExecuting) {
        return;
      } else {
        this.results = [];
        this.isExecuting = true;
      }

      this.ping();
      this.pingTimer = setInterval(this.ping, 1000);

    },
    //--------------------------------------------------------------------------
    focusedURL() {

    },
    //--------------------------------------------------------------------------
    ping() {

      if (this.results.length >= this.maxPings) {
        this.stopExecuting();
        return;
      }

      if (this.isPinging) {
        return;
      } else {
        this.isPinging = true;
      }

      var that = this;
      RobocallsAI.shared().execute(this.method, this.url, this.body, this.includeSessionToken, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isPinging = false;
          that.results.push({
            id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
            time: new Date(),
            code: code,
            body: body
          });
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isPinging = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isPinging = false;
          that.results.push({
            id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
            time: new Date(),
            code: 0,
            body: error
          });
        }
      })
    },
    //--------------------------------------------------------------------------
    stopExecuting() {
      this.isExecuting = false;
      this.isPinging = false;
      if (this.pingTimer != null) {
        clearInterval(this.pingTimer);
        this.pingTimer = null;
      }
    },
    //--------------------------------------------------------------------------
    updateBody() {

    },
  },
  mounted() {}
}
</script>
